


export interface address{
    label: string,
    lat: number, 
    lng: number
}

export interface pet{
    name: string,
    type: string,
    breed?: string
}

export interface profile { 
    id?: string,
    name: string,
    familyMembers: string[],
    addresses: address[],
    pets: pet[]
}


export let Greg:profile = {
    id: "gobears",
    name: "Greg Johnson",
    familyMembers: [
        "Anna Johnson", "Elizabeth Johnson", "Mimi Johnson", "Ryan Johnson", "Rachel Johnson", "Will Johnson"
    ],
    addresses: [
        { label: "Home", lat: 37.919652708535224, lng: -122.49680654439157 },
        { label: "Work", lat: 37.39553879499588, lng: -122.1443454732415 },
        { label: "School", lat: 37.938445595065154, lng: -122.52498018856969 }
    ],
    pets: [
        { name: "Snicker", type: "Dog", breed: "Bernadoodle" },
        { name: "Winter", type: "Cat" },
        { name: "Lulu", type: "Cat" },
    ]
}
import { EventHandler, FormEventHandler, MouseEventHandler, SyntheticEvent, useState, useEffect } from "react";
import Logo from "./logo";
import { Carddata, categories, subcategories, Category, Subcategory, getCategoryTitleFromId, getSubcategoryTitleFromId, getCategoryIDfromSubcategoryID, Intake } from "./ontology";
import { BuildCardPreview, CardPreviewUnregistered, CardPreviewRegistered } from "./buildcard";
import { url } from "inspector";
import { title } from "process";

interface intakeTypes { value: string, label: string};

interface IntakeEntry{
    id?: string,
    question: string,
    type: string,
    options?: string[]
}


function makeid() {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < 9; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


let typeOptions: intakeTypes[] = [
    { value: "0", label: "Choose type" },
    { value: "peopleSelect", label: "People Selector" },
    { value: "petSelect", label: "Pet Selector" },
    { value: "locationPicker", label: "Location" },
    { value: "scheduler", label: "Scheduler" },
    { value: "pricePicker", label: "Price Selector" },
];

let defaultQuestions:any = {
    "peopleSelect": "What people are you planning for?",
    "petSelect": "Which pet(s) is this for?",
    "locationPicker": "Where do you want this to take place?",
    "scheduler": "When do you want this completed by?",
    "pricePicker": "How much do you want to spend?"
}

function IntakeQuestions(props:any) {
    let [id, setID] = useState(props.id);
    
    function updateType(str:string){
        props.onChangeType(id, str);
    }

    function updateQuestion(str:string){
        props.onChangeQuestion(id, str);
    }

    return(
        <div className="todoIntakeInlineWrapper">
            <div className="todoIntakeHeader">
                <div className="todoIntakeN">{ props.count }.</div>
                <select value={ props.type } onChange={ (e) => updateType(e.target.value) }>
                    { typeOptions.map( (type: intakeTypes, index: number) => 
                        <option key={ index } value={ type.value }>{ type.label }</option>
                    )}
                </select>
            </div>
            { props.type !== "0" && (
                <>
                    <div className="todoIntakeQuestion">
                        <input name="todoIntakeQuestion" placeholder={ "Default: " + defaultQuestions[props.type]} onChange={ (e) => updateQuestion(e.target.value)} />
                    </div>
                </>
            )}
        </div>
    )
}



export function CardsEditor() {
    const [ cards, setCards ] = useState([] as Carddata[]); 
    const [ isLoading, setIsLoading] = useState(false);

    function setNewCards(r:any){
        if(r.length > 0 && r[0].hasOwnProperty("id")){
            r.sort( (a:Carddata,b:Carddata) => (a.score > b.score) ? -1 : (b.score > a.score) ? 1 : 0);
            setCards(r);
        }
    }

    useEffect(() => {
        fetch(
        `https://api.gregjohnson.info/ginkgo/getCards.php`
        )
        .then(res => res.json())
        .then(response => {
            setNewCards(response);
            setIsLoading(false);
        })
        .catch(error => console.log(error));
    }, []);

    if(isLoading){
        return(
            <div className="loading">Loading data…</div>
        )
    }

    return(
        <>
            <Logo />
            <section className="buttonWrapper rightAlign">
                <a href="/create" className="button secondary sm">Create New Idea</a>
            </section>
            <div className="tableWrapper">
                <table className="adminTable">
                    <thead>
                        <tr>
                            <th>Actions</th>
                            <th>ID</th>
                            <th>Category/Subcategory</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Image</th>
                            <th className="wideNote">Prospect Note</th>
                            <th className="wideNote">Member Note</th>
                            <th>Weighted Value</th>
                            <th className="todoSteps">Prospect Step 1</th>
                            <th className="todoSteps">Prospect Step 2</th>
                            <th className="todoSteps">Prospect Step 3</th>
                            <th className="todoSteps">Prospect Step 4</th>
                            <th className="todoSteps">Prospect Step 5</th>
                            <th className="intakeQuestion">Intake Q1</th>
                            <th className="intakeQuestion">Intake Q2</th>
                            <th className="intakeQuestion">Intake Q3</th>
                            <th className="intakeQuestion">Intake Q4</th>
                            <th className="intakeQuestion">Intake Q5</th>
                        </tr>
                    </thead>
                    <tbody>
                        { cards.map( (card:Carddata, i:number) => 
                            <CardEditor key={ i } card={ card } />
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}


export function CardEditor(props:any){
    let [ isEditing, setIsEditing ] = useState(false);
    let card = props.card;

    let [subcategory_id, setSubcategory_id] = useState(card.subcategory_id);
    let [title, setTitle] = useState(card.title);
    let [description, setDescription] = useState(card.description);
    let [imageURL, setImageURL] = useState(card.imageURL);
    let [todoDetail, setTodoDetail] = useState(card.todoDetail);
    let [todoStart, setTodoStart] = useState(card.todoStart);
    let [score, setScore] = useState(card.score);

    let [todoStepOne, setTodoStepOne] = useState(card.todoSteps[0]);
    let [todoStepTwo, setTodoStepTwo] = useState(card.todoSteps[1]);
    let [todoStepThree, setTodoStepThree] = useState(card.todoSteps[2]);
    let [todoStepFour, setTodoStepFour] = useState(card.todoSteps[3]);
    let [todoStepFive, setTodoStepFive] = useState(card.todoSteps[4]);

    let initialIntakeOneType, initialIntakeTwoType, initialIntakeThreeType, initialIntakeFourType, initialIntakeFiveType = "";
    let initialIntakeOneQuestion, initialIntakeTwoQuestion, initialIntakeThreeQuestion, initialIntakeFourQuestion, initialIntakeFiveQuestion = "";
    
    if(card.todoIntake.length && card.todoIntake[0].hasOwnProperty("type")){
        initialIntakeOneType = card.todoIntake[0].type;
        initialIntakeOneQuestion = card.todoIntake[0].question;
    }
    if(card.todoIntake.length > 1 && card.todoIntake[1].hasOwnProperty("type")){
        initialIntakeTwoType = card.todoIntake[1].type;
        initialIntakeTwoQuestion = card.todoIntake[1].question;
    }
    if(card.todoIntake.length > 2 && card.todoIntake[2].hasOwnProperty("type")){
        initialIntakeThreeType = card.todoIntake[2].type;
        initialIntakeThreeQuestion = card.todoIntake[2].question;
    }
    if(card.todoIntake.length > 3 && card.todoIntake[3].hasOwnProperty("type")){
        initialIntakeFourType = card.todoIntake[3].type;
        initialIntakeFourQuestion = card.todoIntake[3].question;
    }
    if(card.todoIntake.length > 4 && card.todoIntake[4].hasOwnProperty("type")){
        initialIntakeFiveType = card.todoIntake[4].type;
        initialIntakeFiveQuestion = card.todoIntake[4].question;
    }
    
    // explode intake into separately manageable fields
    let [intakeOneType, setIntakeOneType] = useState(initialIntakeOneType);
    let [intakeOneQuestion, setIntakeOneQuestion] = useState(initialIntakeOneQuestion);
    let [intakeTwoType, setIntakeTwoType] = useState(initialIntakeTwoType);
    let [intakeTwoQuestion, setIntakeTwoQuestion] = useState(initialIntakeTwoQuestion);
    let [intakeThreeType, setIntakeThreeType] = useState(initialIntakeThreeType);
    let [intakeThreeQuestion, setIntakeThreeQuestion] = useState(initialIntakeThreeQuestion);
    let [intakeFourType, setIntakeFourType] = useState(initialIntakeFourType);
    let [intakeFourQuestion, setIntakeFourQuestion] = useState(initialIntakeFourQuestion);
    let [intakeFiveType, setIntakeFiveType] = useState(initialIntakeFiveType);
    let [intakeFiveQuestion, setIntakeFiveQuestion] = useState(initialIntakeFiveQuestion);

    let [editingTitle, updateEditingTitle] = useState(title);
    let [editingDescription, updateEditingDescription] = useState(description);
    let [editingImageURL, updateEditingImageURL] = useState(imageURL);
    let [editingTodoDetail, updateEditingTodoDetail] = useState(todoDetail);
    let [editingTodoStart, updateEditingTodoStart] = useState(todoStart);
    let [editingScore, updateEditingScore] = useState(score);

    let [editingTodoStepOne, updateEditingTodoStepOne] = useState(todoStepOne);
    let [editingTodoStepTwo, updateEditingTodoStepTwo] = useState(todoStepTwo);
    let [editingTodoStepThree, updateEditingTodoStepThree] = useState(todoStepThree);
    let [editingTodoStepFour, updateEditingTodoStepFour] = useState(todoStepFour);
    let [editingTodoStepFive, updateEditingTodoStepFive] = useState(todoStepFive);

    // explode intake into separately manageable fields
    let [editingIntakeOneType, updateEditingIntakeOneType] = useState(initialIntakeOneType);
    let [editingIntakeOneQuestion, updateEditingIntakeOneQuestion] = useState(initialIntakeOneQuestion);
    let [editingIntakeTwoType, updateEditingIntakeTwoType] = useState(initialIntakeTwoType);
    let [editingIntakeTwoQuestion, updateEditingIntakeTwoQuestion] = useState(initialIntakeTwoQuestion);
    let [editingIntakeThreeType, updateEditingIntakeThreeType] = useState(initialIntakeThreeType);
    let [editingIntakeThreeQuestion, updateEditingIntakeThreeQuestion] = useState(initialIntakeThreeQuestion);
    let [editingIntakeFourType, updateEditingIntakeFourType] = useState(initialIntakeFourType);
    let [editingIntakeFourQuestion, updateEditingIntakeFourQuestion] = useState(initialIntakeFourQuestion);
    let [editingIntakeFiveType, updateEditingIntakeFiveType] = useState(initialIntakeFiveType);
    let [editingIntakeFiveQuestion, updateEditingIntakeFiveQuestion] = useState(initialIntakeFiveQuestion);


    function cancelEditing(){
        updateEditingTitle(title);
        updateEditingDescription(description);
        updateEditingImageURL(imageURL);
        updateEditingTodoDetail(todoDetail);
        updateEditingTodoStart(todoStart);
        updateEditingScore(score)
        setIsEditing(false)
    }

    function publishChanges(){
        let newcard = card as Carddata;
        newcard.title = editingTitle;
        newcard.description = editingDescription;
        newcard.imageURL = editingImageURL;
        newcard.todoDetail = editingTodoDetail;
        newcard.todoStart = editingTodoStart;
        newcard.score = editingScore;

        // recompile the steps array of strings
        let newsteps = [] as string[];
        editingTodoStepOne !== "" && newsteps.push(editingTodoStepOne);
        editingTodoStepTwo !== "" && newsteps.push(editingTodoStepTwo);
        editingTodoStepThree !== "" && newsteps.push(editingTodoStepThree);
        editingTodoStepFour !== "" && newsteps.push(editingTodoStepFour);
        editingTodoStepFive !== "" && newsteps.push(editingTodoStepFive);
        
        // recompile the intake array of Intake objects
        let newintake = [] as Intake[];
        editingIntakeOneType !== "" && newintake.push( { type: editingIntakeOneType, question: editingIntakeOneQuestion } )
        editingIntakeTwoType !== "" && newintake.push( { type: editingIntakeTwoType, question: editingIntakeTwoQuestion } )
        editingIntakeThreeType !== "" && newintake.push( { type: editingIntakeThreeType, question: editingIntakeThreeQuestion } )
        editingIntakeFourType !== "" && newintake.push( { type: editingIntakeFourType, question: editingIntakeFourQuestion } )
        editingIntakeFiveType !== "" && newintake.push( { type: editingIntakeFiveType, question: editingIntakeFiveQuestion } )
        newcard.todoIntake = newintake;

        // cleanup routines
        if(newcard.primaryCategory){
            delete newcard.primaryCategory;
        }
        if(newcard.secondaryCategory){
            delete newcard.secondaryCategory;
        }

        newcard.subcategory_id = parseInt(subcategory_id, 10);
        newcard.category_id = getCategoryIDfromSubcategoryID(parseInt(subcategory_id, 10));
        
        pushNewCardToServer(newcard);

        setTitle(editingTitle);
        setDescription(editingDescription);
        setImageURL(editingImageURL);
        setTodoDetail(editingTodoDetail);
        setTodoStart(editingTodoStart);
        setScore(editingScore);
        setIsEditing(false)
        setIntakeOneType(editingIntakeOneType);
        setIntakeOneQuestion(editingIntakeOneQuestion);
        setIntakeTwoType(editingIntakeTwoType);
        setIntakeTwoQuestion(editingIntakeTwoQuestion);
        setIntakeThreeType(editingIntakeThreeType);
        setIntakeThreeQuestion(editingIntakeThreeQuestion);
        setIntakeFourType(editingIntakeFourType);
        setIntakeFourQuestion(editingIntakeFourQuestion);
        setIntakeFiveType(editingIntakeFiveType);
        setIntakeFiveQuestion(editingIntakeFiveQuestion);
        setTodoStepOne(editingTodoStepOne);
        setTodoStepTwo(editingTodoStepTwo);
        setTodoStepThree(editingTodoStepThree);
        setTodoStepFour(editingTodoStepFour);
        setTodoStepFive(editingTodoStepFive);


    }

    function pushNewCardToServer(card:Carddata){
        console.info(card);
        const requestOptions = {
            method: 'POST',
            mode: 'no-cors' as RequestMode,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(card)
        };
        fetch('https://api.gregjohnson.info/ginkgo/putCards.php', requestOptions)
            // .then(response => response.json())
            // .then(data => (setToast("Success!")));
            .then((e) => console.info("Surely this must've been successful."))
    }

    function deleteCard(id:string){
        if(window.confirm("Are you sure you want to delete this card?")){
            fetch('https://api.gregjohnson.info/ginkgo/archiveCard.php?' + new URLSearchParams({
                id: id
            }))
            .then(response => response.json())
            .then((e) => window.location.reload());
        }
    }


    if(isEditing){
        return(
            <tr>
                <td className="editing adminRowEditorButtons">
                    <button className="editorButton" onClick={ (e) => cancelEditing() }>Cancel</button><br /><br />
                    <button className="editorButton" onClick={ (e) => publishChanges() }>Publish</button>
                </td>
                <td className="adminRowID">{ card.id }</td>
                <td className="editing adminRowCategory">
                    <select name="subcategory" onChange={ (e) => setSubcategory_id(e.target.value)} value={ subcategory_id }>
                        <option value="0"></option>
                        { subcategories.map( (ct:Subcategory, index: number) => 
                            <option key={ index } value={ ct.id }>{ getCategoryTitleFromId(ct.category_id) } &gt; { ct.title }</option>
                        )}
                    </select>
                </td>
                <td className="editing adminRowTitle"><textarea name="title" value={ editingTitle } onChange={ (e) => updateEditingTitle(e.target.value) } /></td>
                <td className="editing adminRowDescription"><textarea name="description" rows={7} value={ editingDescription } onChange={ (e) => updateEditingDescription(e.target.value) } /></td>
                <td className="editing adminRowImage">
                    <img className="smPreview" src={ editingImageURL } /><br />
                    <input name="preview" value={ editingImageURL } onChange={ (e) => updateEditingImageURL(e.target.value)} />
                </td>
                <td className="editing adminRowTodoDetail"><textarea name="todoDetail" rows={7} value={ editingTodoDetail } onChange={ (e) => updateEditingTodoDetail(e.target.value) } /></td>
                <td className="editing adminRowTodoStart"><textarea name="todoStart" rows={7} value={ editingTodoStart } onChange={ (e) => updateEditingTodoStart(e.target.value) } /></td>
                <td className="editing adminRowScore"><input name="score" value={ editingScore } onChange={ (e) => updateEditingScore(parseInt(e.target.value, 10)) } /></td>
                <td className="editing adminRowTodoSteps"><textarea name="todoStepOne" rows={5} value={ editingTodoStepOne } onChange={ (e) => updateEditingTodoStepOne(e.target.value)} /></td>
                <td className="editing adminRowTodoSteps"><textarea name="todoStepTwo" rows={5} value={ editingTodoStepTwo } onChange={ (e) => updateEditingTodoStepTwo(e.target.value)} /></td>
                <td className="editing adminRowTodoSteps"><textarea name="todoStepThree" rows={5} value={ editingTodoStepThree } onChange={ (e) => updateEditingTodoStepThree(e.target.value)} /></td>
                <td className="editing adminRowTodoSteps"><textarea name="todoStepFour" rows={5} value={ editingTodoStepFour } onChange={ (e) => updateEditingTodoStepFour(e.target.value)} /></td>
                <td className="editing adminRowTodoSteps"><textarea name="todoStepFive" rows={5} value={ editingTodoStepFive } onChange={ (e) => updateEditingTodoStepFive(e.target.value)} /></td>
                <td className="editing adminRowIntake"><IntakeType editing={ true } updateType={ updateEditingIntakeOneType } id={ card.id } type={ editingIntakeOneType } /><textarea name="intakeOneQuestion" rows={5} value={ editingIntakeOneQuestion } onChange={ (e) => updateEditingIntakeOneQuestion(e.target.value)} /></td>
                <td className="editing adminRowIntake"><IntakeType editing={ true } updateType={ updateEditingIntakeTwoType } id={ card.id } type={ editingIntakeTwoType } /><textarea name="intakeTwoQuestion" rows={5} value={ editingIntakeTwoQuestion } onChange={ (e) => updateEditingIntakeTwoQuestion(e.target.value)} /></td>
                <td className="editing adminRowIntake"><IntakeType editing={ true } updateType={ updateEditingIntakeThreeType } id={ card.id } type={ editingIntakeThreeType } /><textarea name="intakeThreeQuestion" rows={5} value={ editingIntakeThreeQuestion } onChange={ (e) => updateEditingIntakeThreeQuestion(e.target.value)} /></td>
                <td className="editing adminRowIntake"><IntakeType editing={ true } updateType={ updateEditingIntakeFourType } id={ card.id } type={ editingIntakeFourType } /><textarea name="intakeFourQuestion" rows={5} value={ editingIntakeFourQuestion } onChange={ (e) => updateEditingIntakeFourQuestion(e.target.value)} /></td>
                <td className="editing adminRowIntake"><IntakeType editing={ true } updateType={ updateEditingIntakeFiveType } id={ card.id } type={ editingIntakeFiveType } /><textarea name="intakeFiveQuestion" rows={5} value={ editingIntakeFiveQuestion } onChange={ (e) => updateEditingIntakeFiveQuestion(e.target.value)} /></td>
            </tr>
        )
    }
    return(
        <tr>
            <td className="adminRowEditorButtons">
                <button className="editorButton" onClick={ (e) => setIsEditing(true) }>Edit</button><br /><br />
                <button className="editorButton" onClick={ (e) => deleteCard(card.id) }>Delete</button>
            </td>
            <td className="adminRowID">{ card.id }</td>
            <td className="adminRowCategory">{ getCategoryTitleFromId(card.category_id) } &gt; { getSubcategoryTitleFromId(card.subcategory_id) }</td>
            <td className="adminRowTitle">{ title }</td>
            <td className="adminRowDescription">{ description }</td>
            <td className="adminRowImage">
                <img className="smPreview" src={ imageURL } />
            </td>
            <td className="adminRowTodoDetail">{ todoDetail }</td>
            <td className="adminRowTodoStart">{ todoStart }</td>
            <td className="adminRowScore">{ score }</td>
            <td className="adminRowTodoSteps">{ todoStepOne }</td>
            <td className="adminRowTodoSteps">{ todoStepTwo }</td>
            <td className="adminRowTodoSteps">{ todoStepThree }</td>
            <td className="adminRowTodoSteps">{ todoStepFour }</td>
            <td className="adminRowTodoSteps">{ todoStepFive }</td>
            <td className="adminRowIntake"><IntakeType id={ card.id } type={ intakeOneType } />{ intakeOneQuestion }</td>
            <td className="adminRowIntake"><IntakeType id={ card.id } type={ intakeTwoType } />{ intakeTwoQuestion }</td>
            <td className="adminRowIntake"><IntakeType id={ card.id } type={ intakeThreeType } />{ intakeThreeQuestion }</td>
            <td className="adminRowIntake"><IntakeType id={ card.id } type={ intakeFourType } />{ intakeFourQuestion }</td>
            <td className="adminRowIntake"><IntakeType id={ card.id } type={ intakeFiveType } />{ intakeFiveQuestion }</td>
        </tr>
    )
}

function IntakeType(props:any){
    if(props.type != undefined && props.type != ""){
        if(props.editing === true){
            return(
                <select value={ props.type } onChange={ (e) => props.updateType(e.target.value) }>
                    { typeOptions.map( (type: intakeTypes, index: number) => 
                        <option key={ index } value={ type.value }>{ type.label }</option>
                    )}
                </select>
            )
        }
        else{
            return(
                <div className="intakeType">{ props.type }</div>
            )
        }
    }
    if(props.editing === true){
        return(
            <select value={ props.type } onChange={ (e) => props.updateType(e.target.value) }>
                { typeOptions.map( (type: intakeTypes, index: number) => 
                    <option key={ index } value={ type.value }>{ type.label }</option>
                )}
            </select>
        )
    }
    else{
        return(
            <></>
        )
    }
}

export default function Admin(props:any) {
    let [selectedCategory, setSelectedCategory] = useState(0);
    let [selectedSubcategory, setSelectedSubcategory] = useState(0);
    let [title, setTitle] = useState("");
    let [description, setDescription] = useState("");
    let [todoDetail, setTodoDetail] = useState("");
    let [todoStart, setTodoStart] = useState("");
    let [intakeQuestionsList, updateIntakeQuestionsList] = useState([] as IntakeEntry[]);
    let [imageURL, setImageURL] = useState("");
    let [preview, setPreview] = useState(false);
    let [todoSteps, setTodoSteps] = useState(["","","","",""]);
    let [score, setScore] = useState(50);
    let [toast, setToast] = useState("");

    function handleSubmit(e:any) {
        e.preventDefault();
        console.info({
            title: title,
            description: description,
            todoDetail: todoDetail,
            intakeQuestions: intakeQuestionsList,
            todoSteps: todoSteps,
            imageURL: imageURL
        });
        setPreview(true);
    }

    const setCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(event.target.value, 10);
        setSelectedSubcategory(value);
        setSelectedCategory(getCategoryIDfromSubcategoryID(value))
    };

    function addQuestion(e:any){
        const copy = [...intakeQuestionsList];
        e.preventDefault();
        copy.push({
            id: makeid(),
            question: "",
            type: "0"
        });
        updateIntakeQuestionsList(copy);
    }

    function updateStep(i:number, s:string){
        const copy = [...todoSteps];
        copy[i] = s;
        setTodoSteps(copy);
    }

    function onChangeType(id:string, type:string){
        const copy = [...intakeQuestionsList];
        for(let x=0; x< copy.length; x++){
            if(copy[x].id === id){
                copy[x].type = type;
            }
        }
        updateIntakeQuestionsList(copy);
    }

    function onChangeQuestion(id:string, question:string){
        const copy = [...intakeQuestionsList];
        for(let x=0; x< copy.length; x++){
            if(copy[x].id === id){
                copy[x].question = question;
            }
        }
        updateIntakeQuestionsList(copy);
    }

    function updateToast(data:string){
        let affirmations = [
            "You’re doing great and you look wonderful today, too.",
            "Are you feeling all this success yet? Because we are!",
            "w00t! You’re killing it, you know?",
            "It’s…it’s just amazing. Keep doing what you do!",
            "I’d clap but I’m a computer and have no hands…but know I’m clapping virtually for you!"
        ]
        setToast(data + " " + affirmations[Math.floor(Math.random()*affirmations.length)]);
    }

    function publishData() {
        const requestOptions = {
            method: 'POST',
            mode: 'no-cors' as RequestMode,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: makeid(),
                category_id: selectedCategory,
                subcategory_id: selectedSubcategory,
                title: title,
                description: description,
                todoStart: todoStart,
                todoDetail: todoDetail,
                todoIntake: intakeQuestionsList,
                todoSteps: todoSteps,
                imageURL: imageURL,
                score: score
            })
        };
        fetch('https://api.gregjohnson.info/ginkgo/putCards.php', requestOptions)
            // .then(response => response.json())
            // .then(data => (setToast("Success!")));
            .then((e) => updateToast("Great job! This just got posted."))
    }

    return(
        <>
            <a href="/"><Logo /></a>
            <section className="buttonWrapper rightAlign">
                <a href="/admin" className="button secondary sm">Manage All Ideas</a>
            </section>
            <div className="sectionWrapper">
                <section>
                    <div className="categoryHeader">Create New Preset for { getCategoryTitleFromId(selectedCategory) } &gt; { getSubcategoryTitleFromId(selectedSubcategory) }</div>
                    <form className="mainform" onSubmit={ handleSubmit }>
                        <div className="inputWrapper">
                            <label htmlFor="subcategory">Category/Subcategory:</label>
                            <select name="subcategory" onChange={ (e) => setCategory(e)} value={ selectedSubcategory }>
                                <option value="0"></option>
                                { subcategories.map( (ct:Subcategory, index: number) => 
                                    <option key={ index } value={ ct.id }>{ getCategoryTitleFromId(ct.category_id) } &gt; { ct.title }</option>
                                )}
                            </select>
                        </div>
                        <div className="inputWrapper">
                            <label htmlFor="title">Title:</label>
                            <input name="title" placeholder="Make this pithy and desirable" value={ title } onChange={ (e) => setTitle(e.target.value)} />
                        </div>
                        <div className="inputWrapper">
                            <label htmlFor="description">Description:</label>
                            <textarea name="description" placeholder="Shown on the tile to engage the member" value={ description } onChange={ (e) => setDescription(e.target.value)} />
                        </div>
                        <div className="inputWrapper">
                            <label htmlFor="todoDetail">Prospect-Facing To Do:</label>
                            <textarea name="todoDetail" placeholder="This is the pitch to non-registered users to want to use Yohana" value={ todoDetail } onChange={ (e) => setTodoDetail(e.target.value)} />
                        </div>
                        <div className="inputWrapper multi">
                            <label>Prospect-Facing Steps:</label>
                            <div className="todoStepsWrapper">
                                <div className="todoStepsInlineWrapper">
                                    <div className="todoStepsN">1.</div>
                                    <input placeholder="What’s the first step we’re promising?" name="todoSteps1" value={ todoSteps[0] } onChange={ (e) => updateStep(0, e.target.value)} />
                                </div>
                                <div className="todoStepsInlineWrapper">
                                    <div className="todoStepsN">2.</div>
                                    <input placeholder="Then the next step" name="todoSteps2" value={ todoSteps[1] } onChange={ (e) => updateStep(1, e.target.value)} />
                                </div>
                                <div className="todoStepsInlineWrapper">
                                    <div className="todoStepsN">3.</div>
                                    <input placeholder="And so on…" name="todoSteps3" value={ todoSteps[2] } onChange={ (e) => updateStep(2, e.target.value)} />
                                </div>
                                <div className="todoStepsInlineWrapper">
                                    <div className="todoStepsN">4.</div>
                                    <input placeholder="Omit any that are not needed" name="todoSteps4" value={ todoSteps[3] } onChange={ (e) => updateStep(3, e.target.value)} />
                                </div>
                                <div className="todoStepsInlineWrapper">
                                    <div className="todoStepsN">5.</div>
                                    <input placeholder="Never more than 5" name="todoSteps5" value={ todoSteps[4] } onChange={ (e) => updateStep(4, e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="inputWrapper">
                            <label htmlFor="todoStart">Member-Facing Description:</label>
                            <textarea name="todoStart" placeholder="Be colloquial. Remember, this is the start of the elevated service experience." value={ todoStart } onChange={ (e) => setTodoStart(e.target.value)} />
                        </div>
                        <div className="inputWrapper multi">
                            <label htmlFor="todoIntake">Intake Questions:</label>
                            <div className="todoIntakeWrapper">
                                { intakeQuestionsList.length === 0 && (
                                    <div className="emptystate">Click the + button to add a scoping question</div>
                                )}
                                {
                                    intakeQuestionsList.map( (intake:IntakeEntry, index: number) => (
                                        <IntakeQuestions 
                                            id={ intake.id } 
                                            type={ intake.type } 
                                            onChangeType = { onChangeType }
                                            question={ intake.question } 
                                            onChangeQuestion = { onChangeQuestion }
                                            key={ index }
                                            count={ index+1 } 
                                        />        
                                    ))
                                }
                                
                                <button className="roundplus" onClick={ (e) => addQuestion(e) }>+</button>
                            </div>
                        </div>
                        <div className="inputWrapper">
                            <label htmlFor="imageURL">Image URL:</label>
                            <input name="imageURL" placeholder="https://" value={ imageURL } onChange={ (e) => setImageURL(e.target.value)} />
                            { imageURL !== "" && (
                                <div className="imagePreview" style={ {backgroundImage: `url(${imageURL})` }}></div>
                            )}
                        </div>
                        <div className="inputWrapper">
                            <label htmlFor="score">Weighted value:<br />(higher scores show first)</label>
                            <input name="score" type="range" min="1" max="100" step="1" value={ score } onChange={ (e) => setScore(parseInt(e.target.value, 10))} />
                            <div className="showValue">
                                { score } <br />
                                { score < 20 && ( <span className="valueCaption">Low-ranked</span> ) }
                                { score < 40 && score >= 20 && ( <span className="valueCaption">Medium Low</span> ) }
                                { score < 60 && score >= 40 && ( <span className="valueCaption">50 is Default</span> ) }
                                { score < 80 && score >= 60 && ( <span className="valueCaption">High</span> ) }
                                { score < 90 && score >= 80 && ( <span className="valueCaption">Strong Performer</span> ) }
                                { score >= 90 && ( <span className="valueCaption">Leading Idea</span> ) }
                            </div>
                        </div>
                        <div className="buttonWrapper">
                            <input type="button" className="primary button" value="Preview" onClick={ (e) => handleSubmit(e) } />
                        </div>
                    </form>
                </section>
                { preview && (
                    <>
                        <div className="previewHeader">What your tile will look like:</div>
                        <BuildCardPreview title={ title } description={ description } imageURL={ imageURL } />

                        <div className="previewHeader">What the card will look like for unregistered users:</div>
                        <CardPreviewUnregistered title={title} description={ description } todoSteps={ todoSteps } />

                        <div className="previewHeader">What the card will look like for unregistered users:</div>
                        <CardPreviewRegistered title={ title } description={ description } todoStart={ todoStart } todoSteps={ todoSteps } todoIntake={ intakeQuestionsList } />
                        <div className="buttonWrapper">
                            <input type="submit" className="primary button" value="Publish" onClick={ publishData } />
                        </div>
                    </>
                )}

                { toast !== "" && (
                    <div className="toast">
                        { toast }
                        <div className="buttonWrapper">
                            <button className="primary button" onClick={ (e) => window.location.reload() }>Let’s Do Another</button>
                        </div>
                    </div>
                )}

            </div>
        </>
    )
}
import Logo from "./logo";
import Nav from "./nav";


export default function TodoBrowserReg() {
  return (
    <>
      <Logo />
      <Nav loggedin={ true }/>
            
      <div id="detail"></div>
    </>
  );
}

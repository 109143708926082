import React, { useState, useEffect, useRef, MutableRefObject, SyntheticEvent } from "react";
import ReactCSSTransitionGroup from 'react-transition-group';
import Logo from "./logo";
import Nav from "./nav";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { getModeForUsageLocation, parseIsolatedEntityName } from "typescript";
import { Carddata, getCategoryTitleFromId, getSubcategoryTitleFromId } from "./ontology";
import { profile, Greg, address, pet } from "./profile";
import { url } from "inspector";
import navbar from "./images/BottomNav.png";

let loggedInProfile = Greg;


function PeopleSelect(props:any){
    const [active, setActive] = useState([] as number[]);
    
    function addToActive(i:number){
        let newlist = [] as number[];
        for(let x=0;x<active.length;x++){
            if(active[x] === i){
                return true;
            }
            else{
                newlist.push(active[x]);
            }
        }
        newlist.push(i);
        setActive(newlist);
    }

    function removeFromActive(i:number){
        let newlist = [] as number[];
        for(let x=0;x<active.length;x++){
            if(active[x] === i){
                return true;
            }
            else{
                newlist.push(active[x]);
            }
        }
        setActive(newlist);
    }

    function toggleActive(i:number){
        let newlist = [] as number[];
        let found = 0;
        for(let x=0;x<active.length;x++){
            if(active[x] === i){
                found = 1;
            }
            else{
                newlist.push(active[x]);
            }
        }
        if(!found){
            newlist.push(i);
        }
        setActive(newlist);
    }

    let people = ["Me"];
    people = people.concat(loggedInProfile.familyMembers);

    return(
        <div className="buttonCluster">
            { people.map((p:string, i:number) => (
                 <label key={ i } className={ active.indexOf(i) !== -1 ? "fauxButton people active" : "fauxButton" }>
                    <input name="peopleSelect" type="checkbox" value={ p } onChange={ (e) => toggleActive(i) } /><span>{ p }</span>
                </label>
            ))}
        </div>
    )
}

function PetSelect(props:any){
    const [active, setActive] = useState([] as number[]);
    
    function addToActive(i:number){
        let newlist = [] as number[];
        for(let x=0;x<active.length;x++){
            if(active[x] === i){
                return true;
            }
            else{
                newlist.push(active[x]);
            }
        }
        newlist.push(i);
        setActive(newlist);
    }

    function removeFromActive(i:number){
        let newlist = [] as number[];
        for(let x=0;x<active.length;x++){
            if(active[x] === i){
                return true;
            }
            else{
                newlist.push(active[x]);
            }
        }
        setActive(newlist);
    }

    function toggleActive(i:number){
        let newlist = [] as number[];
        let found = 0;
        for(let x=0;x<active.length;x++){
            if(active[x] === i){
                found = 1;
            }
            else{
                newlist.push(active[x]);
            }
        }
        if(!found){
            newlist.push(i);
        }
        setActive(newlist);
    }

    let pets = loggedInProfile.pets;
    return(
        <div className="buttonCluster">
            { pets.map((p:pet, i:number) => (
                 <label key={ i } className={ active.indexOf(i) !== -1 ? "fauxButton pet active" : "fauxButton" }>
                    <input name="petSelect" type="checkbox" value={ p.name } onChange={ (e) => toggleActive(i) } /><span>{ p.name }</span>
                </label>
            ))}
        </div>
    )
}

function simpleScoper(props:any){
    return(
        <>
            <span className="scopingQuestion"></span>
        </>
    )
}

function PricePicker(props:any){
    const [ value, setValue ] = useState(20);
    let low = 10;
    let high = 500;
    
    return(
        <>
            <span className="priceValue">${ low }</span><input type="range" step="10" min={ low } max={ high } value={ value } name="pricePicker" onChange={ (e) => setValue(parseInt(e.target.value, 10)) } /><span className="priceValue">${ high }</span>
            <div className="showValue">${ value }</div>
        </>
    )
}
function PricePickerEach(props:any){
    const [ value, setValue ] = useState(10);
    let low = 5;
    let high = 100;
    return(
        <>
            <span className="priceValue">${ low }/each</span><input type="range" step="1" min={ low } max={ high } value={ value } name="pricePickerEach" onChange={ (e) => setValue(parseInt(e.target.value, 10)) } /><span className="priceValue">${ high }/each</span><br />
            <div className="showValue">${ value }/each</div>
            <input type="checkbox" name="pricePickerEach" value="No limit" />No limit
        </>
    )
}

function QuantitySelect(props:any){
    let low=0;
    let high=100;
    return(
        <>
            <span className="quantityValue">{ low }</span><input type="range" step="1" min={ low } max={ high } name="quantitySelect" /><span className="priceValue">{ high }</span>
        </>
    )
}

function DatePicker(){
    let date = new Date();
    let max = new Date();
    max.setDate(date.getDate() + 90);
    const [value, setValue] = useState(date.toISOString().split('T')[0]);
    
    return(
        <>
            <input type="date" className="inputDate" name="scheduler" value={ value } onChange={ (e) => setValue(e.target.value)} min={ date.toISOString().split('T')[0] } max={ max.toISOString().split('T')[0] } />
        </>
    )
}

function Spinner(){
    return(
        <div className="loading">Loading…</div>
    )
}

function ErrorComponent(){
    return(
        <div className="error">I had an error</div>
    )
}

function MyMapComponent({
    center,
    zoom,
  }: {
    center: google.maps.LatLngLiteral;
    zoom: number;
  }) {
    const ref = useRef() as (any);
  
    useEffect(() => {
      new window.google.maps.Map(ref.current, {
        center,
        zoom,
      });
    });
  
    return <div className="gmaps" ref={ ref} id="map" />;
  }

  function RenderMap(props:any){
    const gmapskey = "AIzaSyB3TxV8IchjzXmdT2PwEi5EZCnDrRiw3ug";
    const zoom = 17;
    let loc = props.loc;
    let label = props.label;

    const render = (status:Status) => {
        switch (status) {
          case Status.LOADING:
            return <Spinner />;
          case Status.FAILURE:
            return <ErrorComponent />;
          case Status.SUCCESS:
            return (
                <div className="locationWrapper">
                    <MyMapComponent center={ loc } zoom={ zoom } />
                    <div className="picker"><input name="locationPicker" value={ label } type="checkbox" /> { label }</div>
                </div>
            );
        }
      };
      
    return(
        <Wrapper apiKey={ gmapskey } render={ render } />
    )
}

function LocationPicker(props:any){
    if(loggedInProfile.addresses.length > 0){
        return(
            <div className="addressesWrapper">
                { loggedInProfile.addresses.map( (address:address, index:number) => 
                    <RenderMap key={ index } label={ address.label } loc={ { lat: address.lat, lng: address.lng } } />
                )}
            </div>
        )
    }

    // empty state
    return (
        <div>No addresses found.</div>
    )
}

function Configurator(props:any){
    let type = props.type;
    if(type === "peopleSelect"){

        return (
            <div className="intakeOptions">
                <PeopleSelect />
            </div>
        )
    }
    else if(type === "petSelect"){
        return (
            <div className="intakeOptions">
                <PetSelect />
            </div>
        )
    }
    else if(type === "pricePicker"){
        return (
            <div className="intakeOptions">
                <PricePicker />
            </div>
        )
    }
    else if(type === "pricePickerEach"){
        return (
            <div className="intakeOptions">
                <PricePickerEach />
            </div>
        )
    }
    else if(type === "quantitySelect"){
        return (
            <div className="intakeOptions">
                <QuantitySelect />
            </div>
        )
    }
    else if(type === "scheduler"){
        return (
            <div className="intakeOptions">
                <DatePicker />
            </div>
        )
    }
    else if(type === "locationPicker"){
        return(
            <div className="intakeOptions">
                <LocationPicker />
            </div>
        )
    }
    return (
        <div className="intakeOptions">
            <div>{ type }</div>
        </div>
    )

    
}

export function Intake(props:any) {
    console.info(props);
    return(
        <>
            <div className="subhead">{ props.it.question }</div>
            <Configurator type={ props.it.type} values={ props.it.values } options={ props.it.options } />
        </>
    )
}


export default function CardSetRegistered(props: any) {
    const [chat, makeChat] = useState("");
    const [active, setActive] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        document.body.classList.toggle('modal-open', isOpen);
    },[isOpen]);

    function reallySetActive(i: number){
        setActive(i);
        makeChat("");
        setIsOpen(i !== 0);
    }

    const [isLoading, setIsLoading] = useState(true);
    const [cards, setCards] = useState([] as Carddata[]);
    
    function setNewCards(r:any){
        if(r.length > 0 && r[0].hasOwnProperty("id")){
            setCards(r);
        }
    }
    useEffect(() => {
        fetch(
        `https://api.gregjohnson.info/ginkgo/getCards.php`
        )
        .then(res => res.json())
        .then(response => {
            response.sort( (a:Carddata,b:Carddata) => (a.score > b.score) ? -1 : (b.score > a.score) ? 1 : 0);
            setNewCards(response);
            setIsLoading(false);
        })
        .catch(error => console.log(error));
    }, []);

    function getCardById(id: number){
        for(let x=0; x<cards.length;x++){
            if(id === cards[x].id){
                return cards[x];
            }
        }
    }

    let currentCard = getCardById(active);

    function parseQtyPieces(arr:any){
        let q = -1;
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "quantitySelect"){
                q = arr[x].value[0];
            }
        }
        if(q === 1){
            return "1 piece";
        }
        if(q>1){
            return q + " pieces";
        }
        return "some furniture";
    }
    
    function parseLocation(arr:any){
        let q = -1;
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "locationPicker"){
                q = arr[x].value[0];
            }
        }
        return q === -1 ? "at a location to be determined" : q;
    }

    function parseDate(arr:any){
        let q = -1;
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "scheduler"){
                q = arr[x].value[0];
            }
        }
        return q === -1 ? "at a time still to be determined" : new Date(q).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) ;
    }

    function parsePeopleString(arr:any){
        console.info(arr);
        let people = [];
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "peopleSelect"){
                people = arr[x].value;
            }
        }
        console.info(people);
        if(people.length === 1){
            return people[0]
        }
        else if(people.length > 1){
            return `${people.slice(0, - 1).join(', ')}, and ${people[people.length - 1]}`;
        }
        return "a few people, but I’m not fully sure yet"
    }

    function parsePetsString(arr:any){
        console.info(arr);
        let pets = [];
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "petSelect"){
                pets = arr[x].value;
            }
        }
        console.info(pets);
        if(pets.length === 1){
            return pets[0]
        }
        else if(pets.length > 1){
            return `${pets.slice(0, - 1).join(', ')}, and ${pets[pets.length - 1]}`;
        }
        return "some of my pets, but I’m not fully sure yet"
    }

    function makeFurnitureString(arr:any){
        let returnstring = "I’m looking to get " + parseQtyPieces(arr) + " assembled, and need your help.";
        returnstring += "\n";
        returnstring += "The installation location is " + parseLocation(arr);
        returnstring += " and I want it installed by " + parseDate(arr);
        return returnstring;
    }

    function makeMealPlanningString(arr:any){
        let returnstring = "I want to plan meals for " + parsePeopleString(arr) + ", and need your help.";
        returnstring += "\n";
        returnstring += "We’ll be eating at " + parseLocation(arr);
        returnstring += " and I want to start my weeknight meals on " + parseDate(arr);
        return returnstring;
    }

    function makeHalloweenCostumesString(arr:any){
        let returnstring = "I want you to help me find Halloween Costumes for " + parsePeopleString(arr) + ".";
        returnstring += "\n";
        returnstring += "I’ll want the costumes to be delivered to " + parseLocation(arr);
        returnstring += " and I want them to arrive before " + parseDate(arr);
        return returnstring;
    }

    function makePetGroomingString(arr:any){
        let returnstring = "I want you to help me find a groomer for " + parsePetsString(arr) + ".";
        returnstring += "\n";
        returnstring += "I want them to be conveniently located to, or even come to " + parseLocation(arr);
        returnstring += " and I would like this done before " + parseDate(arr);
        return returnstring;
    }

    function stringPattern(type: string, nv: object){
        // "I’m looking for Snicker to get a groomer near my Home at 30 Privateer Drive, Corte Madera, CA 94924 on Monday November 28. My budget is between $50-$150."

        if(type === "Furniture Installation"){
            return makeFurnitureString(nv);
        }
        else if(type === "Weeknight Dinners"){
            return makeMealPlanningString(nv)
        }
        else if(type === "Halloween Costumes"){
            return makeHalloweenCostumesString(nv);
        }
        else if(type === "Pet Grooming"){
            return makePetGroomingString(nv);
        }
    }


    function processSubmit(e: SyntheticEvent){
        e.preventDefault();
        let form = e.target as HTMLFormElement;
        let formdata = new FormData(form);

        let nv = [];
        if(currentCard !== undefined){
            for(let x=0; x<currentCard.todoIntake.length; x++){
                let n = currentCard.todoIntake[x].type;
                let v = formdata.getAll(n);
                let o = { name: n, value: v }
                console.info(o);
                nv.push(o);
            }
        }
        let ae = document.getElementById("anything_else") as HTMLTextAreaElement;
        if(ae.value !== ""){
            nv.push({ name: "anything_else", value: ae.value })
        }

        let htmlrequest = document.getElementById("request") as HTMLInputElement;
        let des = htmlrequest.value;

        let returnstring = "======\n\n";
        returnstring += "I’ve got a New Request for " + des + "\n";
        returnstring += stringPattern(des, nv);
        
        if(ae.value.length){
            returnstring += "\n\n";
            returnstring += "I also wanted you to know:" + "\n";
            returnstring += ae.value;
        }
        
        
        makeChat(returnstring);
    }

    return(
        <>
            <Logo />
            <Nav loggedin={ true }/>
            <div className="sectionWrapper">
                <div className="categoryHeader greeting">Hi, { loggedInProfile.name.split(" ")[0] }!<br />We have a few ideas<br />that you might love.</div>
                <section className="cardHolder">
                    { cards.map((card, i) => (
                        <div key={ i } id={ "card_" + card.id + "_" + card.score } className={ card.id === active ? "card active" : "card"} onClick={() => reallySetActive(card.id) }>
                            <div className="textWrapper">
                                <div className="card_title">{ card.title }</div>
                                <div className="card_description">{ card.description }</div>
                            </div>
                            <div className="imageWrapper" style={{backgroundImage: `url(${card.imageURL})` }}></div>
                        </div>
                    ))}
                </section>
            </div>
            <div className="mobileAppBar"><img src={ navbar } /></div>
            { (active !== 0) ? (
                <div className="modalWrapper">
                    <div className="modalCloser" onClick={ () => reallySetActive(0) }></div>
                    <div className="cardModal" id="cardModal">
                        <div className="cardMeta">Create a new todo</div>
                        <div className="cardPreheader">
                            <div className="imageWatermark" style={{backgroundImage: `url(${currentCard?.imageURL})` }}></div>
                            <div className="closeButton" onClick={ () => reallySetActive(0) }>×</div>
                            { getCategoryTitleFromId(currentCard?.category_id) } &gt; { getSubcategoryTitleFromId(currentCard?.subcategory_id) }
                            <div className="cardHeader">
                                { currentCard?.title }
                            </div>    
                        </div>
                        <div className="subhead">What I can do for you</div>
                        <div className="body">
                            { currentCard?.todoStart }
                            <div className="signoff">- Allison</div>
                        </div>
                        <form id="modalForm" onSubmit={ (e) => processSubmit(e) }>
                            <input type="hidden" id="request" name="request" value={ currentCard?.title } />
                            <div className="bodyIntake">
                                { currentCard?.todoIntake.map((it, i) => (
                                    <Intake it={ it } key={ i } />
                                ))}
                            </div>
                            <div className="subhead">Anything else we should know?</div>
                            <div className="textarea">
                                <textarea id="anything_else" name="anything_else" rows={4}  placeholder="The more we know, the faster we can give you support!"></textarea>
                            </div>
                            <div className="assignment"><span className="avatar allison"></span><span className="name">Allison</span> is ready to help with this.</div>
                            <div className="buttonWrapper">
                                <input type="submit" className="button primary" value="Do this for me" />
                            </div>
                        </form>
                        { chat !== "" && (
                            <div className="chatText">
                                <pre>{ chat }</pre>
                            </div>
                        )}
                    </div>
                </div>
            ) : null }

            <div className="more"><a href="/ideas">Want to see more?</a></div>
        </>
    )
}


export function getCategoryTitleFromId(id: number | undefined){
    if(id === undefined){
        return "";
    }

    for(let x=0;x<categories.length;x++){
        if(categories[x].id === id){
            return categories[x].title;
        }
    }
    return "";
}

export function getSubcategoryTitleFromId(id: number | undefined){
    if(id === undefined){
        return "";
    }
    
    for(let x=0;x<subcategories.length;x++){
        if(subcategories[x].id === id){
            return subcategories[x].title;
        }
    }
    return "";
}

export function getCategoryIDfromSubcategoryID(id: number){
    for(let x=0;x<subcategories.length;x++){
        if(subcategories[x].id === id){
            return subcategories[x].category_id;
        }
    }
    return 0;
}

export interface Intake{
    question: string,
    type: string,
    options?: string[]
}

export interface Carddata{
    id: number,
    category_id: number,
    primaryCategory?: string,
    subcategory_id: number,
    secondaryCategory?: string,
    title: string,
    description: string,
    todoDetail: string,
    todoSteps: string[],
    todoStart: string,
    todoIntake: Intake[],
    imageURL: string,
    score: number
}

export interface Category {
    id: number,
    title: string,
    description: string,
    defaultWeight: number,
    imageURL?: string
}

export interface Subcategory{
    id: number,
    primaryCategory?: string,
    category_id: number,
    title: string,
    description: string,
    defaultWeight: number,
    imageURL?: string
}

export const categories:Category[] = [
    { id: 1, title: "Home", description: "Home", defaultWeight: 100 },
    { id: 2, title: "Travel", description: "Travel", defaultWeight: 98 },
    { id: 3, title: "Meals", description: "Meals", defaultWeight: 96 },
    { id: 4, title: "Events", description: "Events", defaultWeight: 94 },
    { id: 5, title: "Shopping", description: "Shopping", defaultWeight: 92 },
    { id: 6, title: "Activities", description: "Activities", defaultWeight: 90 },
    { id: 7, title: "Kids", description: "Kids", defaultWeight: 88 },
    { id: 8, title: "Pets", description: "Pets", defaultWeight: 86 },
    { id: 9, title: "Auto", description: "Auto", defaultWeight: 84 },
    { id: 10, title: "Self Care", description: "Self Care", defaultWeight: 82 },
    { id: 11, title: "Medical Care", description: "Medical Care", defaultWeight: 80 },
    { id: 99, title: "Other", description: "Other", defaultWeight: 40 }
]

export const subcategories:Subcategory[] = [
    { id: 101, primaryCategory: "Home", category_id: 1, title: "Cleaning", description: "Cleaning", defaultWeight: 50 },
    { id: 102, primaryCategory: "Home", category_id: 1, title: "Handyman", description: "Handyman", defaultWeight: 48 },
    { id: 103, primaryCategory: "Home", category_id: 1, title: "Landscaping", description: "Landscaping", defaultWeight: 46 },
    { id: 104, primaryCategory: "Home", category_id: 1, title: "Runner", description: "Runner", defaultWeight: 44 },
    { id: 105, primaryCategory: "Home", category_id: 1, title: "Plumbing", description: "Plumbing", defaultWeight: 42 },
    { id: 106, primaryCategory: "Home", category_id: 1, title: "Organization", description: "Organization", defaultWeight: 40 },
    { id: 107, primaryCategory: "Home", category_id: 1, title: "Moving", description: "Moving", defaultWeight: 38 },
    { id: 108, primaryCategory: "Home", category_id: 1, title: "Appliance Repair", description: "Appliance Repair", defaultWeight: 36 },
    { id: 109, primaryCategory: "Home", category_id: 1, title: "HVAC", description: "HVAC", defaultWeight: 34 },
    { id: 110, primaryCategory: "Home", category_id: 1, title: "Painting", description: "Painting", defaultWeight: 32 },
    { id: 111, primaryCategory: "Home", category_id: 1, title: "Electrical", description: "Electrical", defaultWeight: 30 },

    { id: 201, primaryCategory: "Travel", category_id: 2, title: "Lodging", description: "Lodging", defaultWeight: 50 },
    { id: 202, primaryCategory: "Travel", category_id: 2, title: "Flights", description: "Flights", defaultWeight: 48 },
    { id: 203, primaryCategory: "Travel", category_id: 2, title: "Cars", description: "Cars", defaultWeight: 46 },
    { id: 204, primaryCategory: "Travel", category_id: 2, title: "Tours", description: "Tours", defaultWeight: 44 },
    { id: 205, primaryCategory: "Travel", category_id: 2, title: "Activities", description: "Activities", defaultWeight: 42 },
    { id: 206, primaryCategory: "Travel", category_id: 2, title: "Cruises", description: "Cruises", defaultWeight: 40 },
    { id: 207, primaryCategory: "Travel", category_id: 2, title: "Packages", description: "Packages", defaultWeight: 38 },
    { id: 208, primaryCategory: "Travel", category_id: 2, title: "Destinations", description: "Destinations", defaultWeight: 36 },
    { id: 209, primaryCategory: "Travel", category_id: 2, title: "Itineraries", description: "Itineraries", defaultWeight: 34 },

    { id: 301, primaryCategory: "Meals", category_id: 3, title: "Meal Kits", description: "Meal Kits", defaultWeight: 50 },
    { id: 302, primaryCategory: "Meals", category_id: 3, title: "Meal Planning", description: "Meal Planning", defaultWeight: 48 },
    { id: 303, primaryCategory: "Meals", category_id: 3, title: "Grocery Delivery", description: "Grocery Delivery", defaultWeight: 46 },
    { id: 304, primaryCategory: "Meals", category_id: 3, title: "Dining", description: "Dining", defaultWeight: 44 },
    { id: 305, primaryCategory: "Meals", category_id: 3, title: "Recipes", description: "Recipes", defaultWeight: 42 },
    { id: 306, primaryCategory: "Meals", category_id: 3, title: "Chef Services", description: "Chef Services", defaultWeight: 40 },
    { id: 307, primaryCategory: "Meals", category_id: 3, title: "Nutritionist", description: "Nutritionist", defaultWeight: 38 },
    { id: 308, primaryCategory: "Meals", category_id: 3, title: "Cooking Classes", description: "Cooking Classes", defaultWeight: 36 },
    { id: 309, primaryCategory: "Meals", category_id: 3, title: "Catering", description: "Catering", defaultWeight: 34 },

    { id: 401, primaryCategory: "Events", category_id: 4, title: "Birthday Party", description: "Birthday Party", defaultWeight: 50 },
    { id: 402, primaryCategory: "Events", category_id: 4, title: "Holiday Costumes", description: "Holiday Costumes", defaultWeight: 48 },

    { id: 501, primaryCategory: "Shopping", category_id: 5, title: "Clothing", description: "Clothing", defaultWeight: 50 },
    { id: 502, primaryCategory: "Shopping", category_id: 5, title: "Gifts", description: "Gifts", defaultWeight: 48 },
    { id: 503, primaryCategory: "Shopping", category_id: 5, title: "Kitchen", description: "Kitchen", defaultWeight: 46 },
    { id: 504, primaryCategory: "Shopping", category_id: 5, title: "Electronics", description: "Electronics", defaultWeight: 44 },
    { id: 505, primaryCategory: "Shopping", category_id: 5, title: "Books", description: "Books", defaultWeight: 42 },
    { id: 506, primaryCategory: "Shopping", category_id: 5, title: "Décor", description: "Décor", defaultWeight: 40 },
    { id: 507, primaryCategory: "Shopping", category_id: 5, title: "Home", description: "Home", defaultWeight: 38 },
    { id: 508, primaryCategory: "Shopping", category_id: 5, title: "Furniture", description: "Furniture", defaultWeight: 36 },
    { id: 509, primaryCategory: "Shopping", category_id: 5, title: "Beauty", description: "Beauty", defaultWeight: 34 },

    { id: 601, primaryCategory: "Activities", category_id: 6, title: "Hiking", description: "Hiking", defaultWeight: 50 },
    { id: 602, primaryCategory: "Activities", category_id: 6, title: "Skiing", description: "Skiing", defaultWeight: 48 },
    { id: 603, primaryCategory: "Activities", category_id: 6, title: "Golf", description: "Golf", defaultWeight: 46 },
    { id: 604, primaryCategory: "Activities", category_id: 6, title: "Girl’s/Guy’s Weekend", description: "Girl’s/Guy’s Weekend", defaultWeight: 44 },
    { id: 605, primaryCategory: "Activities", category_id: 6, title: "Date Night", description: "Date Night", defaultWeight: 42 },
    { id: 606, primaryCategory: "Activities", category_id: 6, title: "Fitness", description: "Fitness", defaultWeight: 40 },
    { id: 607, primaryCategory: "Activities", category_id: 6, title: "Gym", description: "Gym", defaultWeight: 38 },
    { id: 608, primaryCategory: "Activities", category_id: 6, title: "Events", description: "Events", defaultWeight: 36 },
    { id: 609, primaryCategory: "Activities", category_id: 6, title: "Dance", description: "Dance", defaultWeight: 34 },

    { id: 701, primaryCategory: "Kids", category_id: 7, title: "Entertainment", description: "Entertainment", defaultWeight: 50 },
    { id: 702, primaryCategory: "Kids", category_id: 7, title: "Sports", description: "Sports", defaultWeight: 50 },
    { id: 703, primaryCategory: "Kids", category_id: 7, title: "Childcare", description: "Childcare", defaultWeight: 50 },
    { id: 704, primaryCategory: "Kids", category_id: 7, title: "Camps", description: "Camps", defaultWeight: 50 },
    { id: 705, primaryCategory: "Kids", category_id: 7, title: "Activities", description: "Activities", defaultWeight: 50 },
    { id: 706, primaryCategory: "Kids", category_id: 7, title: "Events", description: "Events", defaultWeight: 50 },
    { id: 707, primaryCategory: "Kids", category_id: 7, title: "Transportation", description: "Transportation", defaultWeight: 50 },
    { id: 708, primaryCategory: "Kids", category_id: 7, title: "Education", description: "Education", defaultWeight: 50 },
    { id: 709, primaryCategory: "Kids", category_id: 7, title: "Appointments", description: "Appointments", defaultWeight: 50 },
    
    { id: 801, primaryCategory: "Pets", category_id: 8, title: "Grooming", description: "Grooming", defaultWeight: 50 },
    { id: 802, primaryCategory: "Pets", category_id: 8, title: "Dog Walker", description: "Dog Walker", defaultWeight: 48 },
    { id: 803, primaryCategory: "Pets", category_id: 8, title: "Veterinary Care", description: "Veterinary Care", defaultWeight: 46 },
    { id: 804, primaryCategory: "Pets", category_id: 8, title: "Boarding", description: "Boarding", defaultWeight: 44 },

    { id: 901, primaryCategory: "Auto", category_id: 9, title: "Repairs/Maintenance", description: "Repairs/Maintenance", defaultWeight: 50 },
    { id: 902, primaryCategory: "Auto", category_id: 9, title: "Purchase", description: "Purchase", defaultWeight: 48 },
    { id: 903, primaryCategory: "Auto", category_id: 9, title: "Sell", description: "Sell", defaultWeight: 46 },

    { id: 1001, primaryCategory: "Self Care", category_id: 10, title: "Spa", description: "Spa", defaultWeight: 50 },
    { id: 1002, primaryCategory: "Self Care", category_id: 10, title: "Massage", description: "Massage", defaultWeight: 48 },
    { id: 1003, primaryCategory: "Self Care", category_id: 10, title: "Salon", description: "Salon", defaultWeight: 46 },
    { id: 1004, primaryCategory: "Self Care", category_id: 10, title: "Esthetician", description: "Esthetician", defaultWeight: 44 },
    { id: 1005, primaryCategory: "Self Care", category_id: 10, title: "Hair Salon/Barber", description: "Hair Salon/Barber", defaultWeight: 42 },
    { id: 1006, primaryCategory: "Self Care", category_id: 10, title: "Life Coach", description: "Life Coach", defaultWeight: 40 },

    { id: 1101, primaryCategory: "Medical Care", category_id: 11, title: "Find a New Provider", description: "Find a New Provider", defaultWeight: 50 },
    { id: 1102, primaryCategory: "Medical Care", category_id: 11, title: "Manage Appointments (existing provider)", description: "Manage Appointments (existing provider)", defaultWeight: 48 },
    { id: 1103, primaryCategory: "Medical Care", category_id: 11, title: "Pharmacy/Rx Delivery", description: "Pharmacy/Rx Delivery", defaultWeight: 46 },

    { id: 9999, primaryCategory: "Other", category_id: 99, title: "Just Tell Us What You Need", description: "For whatever else you can’t find a solution for, just ask. We’re here to help.", defaultWeight: 50 },
]
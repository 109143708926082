import React, { useState, useEffect } from "react";
import ReactCSSTransitionGroup from 'react-transition-group';
import Logo from "./logo";
import Nav from "./nav";
import { Carddata, getCategoryTitleFromId, getSubcategoryTitleFromId } from "./ontology";
import navbar from "./images/BottomNav.png";

export default function CardSetUnregistered(props: any) {
    const [active, setActive] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        document.body.classList.toggle('modal-open', isOpen);
    },[isOpen]);

    
    
    const [isLoading, setIsLoading] = useState(true);
    const [cards, setCards] = useState([] as Carddata[]);
    
    function setNewCards(r:any){
        if(r.length > 0 && r[0].hasOwnProperty("id")){
            r.sort( (a:Carddata,b:Carddata) => (a.score > b.score) ? -1 : (b.score > a.score) ? 1 : 0);
            setCards(r);
        }
    }

    useEffect(() => {
        fetch(
        `https://api.gregjohnson.info/ginkgo/getCards.php`
        )
        .then(res => res.json())
        .then(response => {
            setNewCards(response);
            setIsLoading(false);
        })
        .catch(error => console.log(error));
    }, []);


    function reallySetActive(i: number){
        setActive(i);
        setIsOpen(i !== 0);
    }

    function getCardById(id: number){
        for(let x=0; x<cards.length;x++){
            if(id === cards[x].id){
                return cards[x];
            }
        }
    }

    let currentCard = getCardById(active);

    return(
        <>
            <Logo />
            <Nav loggedin={ false }/>
            <div className="mobileAppBar"><img src={ navbar } /></div>
            <div className="sectionWrapper">
                <div className="categoryHeader">
                    <span className="caption">Popular ideas on Yohana</span>
                </div>
                <section className="cardHolder">
                    { cards.map((card, i) => (
                        <div key={ i } id={ "card_" + card.id + "_" + card.score } className={ card.id === active ? "card active" : "card"} onClick={() => reallySetActive(card.id) }>
                            <div className="textWrapper">
                                <div className="card_title">{ card.title }</div>
                                <div className="card_description">{ card.description }</div>
                            </div>
                            <div className="imageWrapper" style={{backgroundImage: `url(${card.imageURL})` }}></div>
                        </div>
                    ))}
                </section>
            </div>
            { (active !== 0) ? (
                <div className="modalWrapper">
                    <div className="modalCloser" onClick={ () => reallySetActive(0) }></div>
                    <div className="cardModal" id="cardModal">
                        <div className="cardPreheader">
                            <div className="imageWatermark" style={{backgroundImage: `url(${currentCard?.imageURL})` }}></div>
                            <div className="closeButton" onClick={ () => reallySetActive(0) }>×</div>
                            { getCategoryTitleFromId(currentCard?.category_id) } &gt; { getSubcategoryTitleFromId(currentCard?.subcategory_id) }
                            <div className="cardHeader">
                                { currentCard?.title }
                            </div>    
                        </div>
                        <div className="subhead">What you asked for</div>
                        <div className="body">{ currentCard?.todoDetail } </div>
                        <div className="subhead">What we’ll do</div>
                        <ol className="bodyList">
                            { currentCard?.todoSteps.map((step, i) => (
                                <li key={i}>{ step }</li>
                            ))}
                        </ol>
                        <div className="callout">To Dos are for subscribers only.<br />Would you like to join?</div>
                        <div className="buttonWrapper">
                            <a href="/home" className="button primary">Subscription Options</a>
                        </div>
                    </div>
                </div>
            ) : null }
            <div className="more"><a className="caption" href="/">Want to see more?</a></div>
        </>
    )
}


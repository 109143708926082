import { useState, useEffect } from "react";
import Logo from "./logo";
import Nav from "./nav";
import { Carddata, categories, subcategories, Category, Subcategory, getCategoryTitleFromId, getSubcategoryTitleFromId } from "./ontology";
import { BuildClosedCardUnregistered } from "./buildcard";
import navbar from "./images/BottomNav.png";

function getCategoryTitleById (id:number) {
  for(let x=0;x < categories.length; x++){
    if(categories[x].id === id){
      return categories[x].title;
    }
  }
}

function getSubcategoryTitleById (id:number) {
  for(let x=0;x < subcategories.length; x++){
    if(subcategories[x].id === id){
      return subcategories[x].title;
    }
  }
}

export default function TodoBrowser() {
  let [ category, setCategory ] = useState(0);
  let [ subcategory, setSubcategory ] = useState(0);
  const [active, setActive] = useState(0);
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
      document.body.classList.toggle('modal-open', isOpen);
  },[isOpen]);

  function reallySetActive(i: number){
      setActive(i);
      setIsOpen(i !== 0);
  }

    const [isLoading, setIsLoading] = useState(true);
    const [cards, setCards] = useState([] as Carddata[]);
    
    function setNewCards(r:any){
        if(r.length > 0 && r[0].hasOwnProperty("id")){
            setCards(r);
        }
    }
    useEffect(() => {
        fetch(
        `https://api.gregjohnson.info/ginkgo/getCards.php`
        )
        .then(res => res.json())
        .then(response => {
            setNewCards(response);
            setIsLoading(false);
        })
        .catch(error => console.log(error));
    }, []);

  function getCardById(id: number){
      for(let x=0; x<cards.length;x++){
          if(id === cards[x].id){
              return cards[x];
          }
      }
  }

  let currentCard = getCardById(active);


  return (
    <>
      <Logo />
      <Nav loggedin={ false }/>
      <div className="mobileAppBar"><img src={ navbar } /></div>

      <div className="categoryHeader">
      { category === 0 && subcategory === 0 ? 
          <span className="nav active">Viewing All.<br /><a className="nav active" href="/discover">Want to go back Home?</a></span>
          : null
        }

        { category > 0 && subcategory === 0 ? 
          <><span className="nav" onClick={ (e) => (setCategory(0), setSubcategory(0))}>View All</span><span className="separator">&gt;</span><span className="nav active">{ getCategoryTitleById(category) }</span></>
          : null
        }

        { category > 0 && subcategory > 0 ?
          <><span className="nav" onClick={ (e) => (setCategory(0), setSubcategory(0))}>View All</span><span className="separator">&gt;</span><span className="nav" onClick={ (e) => setSubcategory(0) } >{ getCategoryTitleById(category) }</span><span className="separator">&gt;</span><span className="nav active">{ getSubcategoryTitleById(subcategory) }</span></>
        : null }
      </div>
        
      <div className="cardHolder">

        { category === 0 && subcategory === 0 ? 
          categories.map( (cat:Category, index:number) => 
            <div className="categoryCard card" onClick={ (e) => setCategory(index+1) } key={ index } title={ cat.description }>
              <div className="textWrapper">
                {cat.title}
              </div>
            </div>
          ) 
          : null
        }

        { category > 0 && subcategory === 0 ? 
          subcategories.filter( sub => sub.category_id === category ).map( (filteredSub:Subcategory, index:number) => (
            <div className="subcategoryCard card" key={ index } onClick={ (e) => setSubcategory(filteredSub.id) }>
              <div className="textWrapper">
                { filteredSub.title } 
              </div>
            </div>
          ))
          : null
        }

        { category > 0 && subcategory > 0 && cards.filter( d => d.subcategory_id === subcategory ).length > 0 ?
          cards.filter( d => d.subcategory_id === subcategory ).map ( (filteredSubData:Carddata, index:Number) => (
            <BuildClosedCardUnregistered card={ filteredSubData } handleClick={ reallySetActive }/>
          ))
        : null
        }

        { category > 0 && subcategory > 0 && cards.filter( d => d.subcategory_id === subcategory ).length === 0 ?
          <div className="emptystate">Sorry, we don’t have anything here yet.</div>
        : null
        }


      </div>
      { (active !== 0) ? (
          <div className="modalWrapper">
              <div className="modalCloser" onClick={ () => reallySetActive(0) }></div>
              <div className="cardModal" id="cardModal">
                   <div className="cardPreheader">
                      <div className="imageWatermark" style={{backgroundImage: `url(${currentCard?.imageURL})` }}></div>
                      <div className="closeButton" onClick={ () => reallySetActive(0) }>×</div>
                      { getCategoryTitleFromId(currentCard?.category_id) } &gt; { getSubcategoryTitleFromId(currentCard?.subcategory_id) }
                      <div className="cardHeader">
                          { currentCard?.title }
                      </div>    
                  </div>
                  <div className="subhead">What you asked for</div>
                  <div className="body">{ currentCard?.todoDetail } </div>
                  <div className="subhead">What we’ll do</div>
                  <ol className="bodyList">
                      { currentCard?.todoSteps.map((step, i) => (
                        step !== "" && 
                          <li key={i}>{ step }</li>
                      ))}
                  </ol>
                  <div className="callout">To Dos are for subscribers only.<br />Would you like to join?</div>
                  <div className="buttonWrapper">
                      <a href="/home" className="button primary">Subscription Options</a>
                  </div>
              </div>
          </div>
      ) : null }
    </>
  );
}


import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Ack!</h1>
      <p>You asked for something. I didn’t have it. </p>
    </div>
  );
}
import React, { useState } from "react";
import { NavLink, matchRoutes, useLocation } from "react-router-dom";

export default function Nav(props: any){
    let loggedIn = props.loggedin;
    let activeClassName = "active";
    
    const location = useLocation();
    const currPath = location.pathname;
    const isRoot = currPath === "/";

    return(
        <nav id="mainnav">

            { loggedIn ? (
                <NavLink to="/discover">Sign Out</NavLink>
            ) : (
                <NavLink to="/home">Sign In</NavLink>
            )}
        </nav>
    )
}
import React, { useState, useEffect, SyntheticEvent, MouseEventHandler } from "react";
import ReactCSSTransitionGroup from 'react-transition-group';
import Logo from "./logo";
import Nav from "./nav";
import Data from "./data";
import { Carddata } from "./ontology"; 
import CardSetRegistered, { Intake } from "./home";

export function BuildClosedCardUnregistered(props: { card: Carddata, key?: any, handleClick: Function }){
    return (
        <div className="card" key={ props.key } id={ "card_" + props.card.id + "_" + props.card.score } onClick={ () => props.handleClick(props.card.id)}>
            <div className="textWrapper">
                <div className="card_title">{ props.card.title }</div>
                <div className="card_description">{ props.card.description }</div>
            </div>
            <div className="imageWrapper" style={{backgroundImage: `url(${props.card.imageURL})` }}></div>
        </div>
    )
}


export function BuildCardPreview(props:any){
    return (
        <div className="card" key={ props.key }>
            <div className="textWrapper">
                <div className="card_title">{ props.title }</div>
                <div className="card_description">{ props.description }</div>
            </div>
            <div className="imageWrapper" style={{backgroundImage: `url(${props.imageURL})` }}></div>
        </div>
    )
}





export function CardPreviewUnregistered(props: any) {
    return(
        <div className="cardModal" id="cardModal">
            <div className="closeButton">×</div>
            <div className="cardMeta">Create a new todo</div>
            <div className="cardHeader">
                { props.title }
            </div>
            <div className="subhead">What you asked for</div>
            <div className="body">{ props.description } </div>
            <div className="subhead">What we’ll do</div>
            <ol className="bodyList">
                { props.todoSteps.map((step:string, i:number) => (
                    step !== "" && ( <li key={i}>{ step }</li> )
                ))}
            </ol>
            <div className="callout">To Dos are for subscribers only. Would you like to join?</div>
            <button className="button primary">Subscription Options</button>
        </div>
    )
}





export function CardPreviewRegistered(props: any) {
    
    function parseQtyPieces(arr:any){
        let q = -1;
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "quantitySelect"){
                q = arr[x].value[0];
            }
        }
        if(q === 1){
            return "1 piece";
        }
        if(q>1){
            return q + " pieces";
        }
        return "some furniture";
    }
    
    function parseLocation(arr:any){
        let q = -1;
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "locationPicker"){
                q = arr[x].value[0];
            }
        }
        return q === -1 ? "at a location to be determined" : q;
    }

    function parseDate(arr:any){
        let q = -1;
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "scheduler"){
                q = arr[x].value[0];
            }
        }
        return q === -1 ? "at a time still to be determined" : new Date(q).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) ;
    }

    function parsePeopleString(arr:any){
        console.info(arr);
        let people = [];
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "peopleSelect"){
                people = arr[x].value;
            }
        }
        console.info(people);
        if(people.length === 1){
            return people[0]
        }
        else if(people.length > 1){
            return `${people.slice(0, - 1).join(', ')}, and ${people[people.length - 1]}`;
        }
        return "a few people, but I’m not fully sure yet"
    }

    function parsePetsString(arr:any){
        console.info(arr);
        let pets = [];
        for(let x=0; x<arr.length; x++){
            if(arr[x].name === "petSelect"){
                pets = arr[x].value;
            }
        }
        console.info(pets);
        if(pets.length === 1){
            return pets[0]
        }
        else if(pets.length > 1){
            return `${pets.slice(0, - 1).join(', ')}, and ${pets[pets.length - 1]}`;
        }
        return "some of my pets, but I’m not fully sure yet"
    }

    function makeFurnitureString(arr:any){
        let returnstring = "I’m looking to get " + parseQtyPieces(arr) + " assembled, and need your help.";
        returnstring += "\n";
        returnstring += "The installation location is " + parseLocation(arr);
        returnstring += " and I want it installed by " + parseDate(arr);
        return returnstring;
    }

    function makeMealPlanningString(arr:any){
        let returnstring = "I want to plan meals for " + parsePeopleString(arr) + ", and need your help.";
        returnstring += "\n";
        returnstring += "We’ll be eating at " + parseLocation(arr);
        returnstring += " and I want to start my weeknight meals on " + parseDate(arr);
        return returnstring;
    }

    function makeHalloweenCostumesString(arr:any){
        let returnstring = "I want you to help me find Halloween Costumes for " + parsePeopleString(arr) + ".";
        returnstring += "\n";
        returnstring += "I’ll want the costumes to be delivered to " + parseLocation(arr);
        returnstring += " and I want them to arrive before " + parseDate(arr);
        return returnstring;
    }

    function makePetGroomingString(arr:any){
        let returnstring = "I want you to help me find a groomer for " + parsePetsString(arr) + ".";
        returnstring += "\n";
        returnstring += "I want them to be conveniently located to, or even come to " + parseLocation(arr);
        returnstring += " and I would like this done before " + parseDate(arr);
        return returnstring;
    }

    function stringPattern(type: string, nv: object){
        // "I’m looking for Snicker to get a groomer near my Home at 30 Privateer Drive, Corte Madera, CA 94924 on Monday November 28. My budget is between $50-$150."

        if(type === "Furniture Installation"){
            return makeFurnitureString(nv);
        }
        else if(type === "Weeknight Dinners"){
            return makeMealPlanningString(nv)
        }
        else if(type === "Halloween Costumes"){
            return makeHalloweenCostumesString(nv);
        }
        else if(type === "Pet Grooming"){
            return makePetGroomingString(nv);
        }
    }

    
    return(
        <div className="cardModal" id="cardModal">
            <div className="closeButton">×</div>
            <div className="cardMeta">Create a new todo</div>
            <div className="cardPreheader">{ props.primaryCategory } &gt; { props.secondaryCategory }</div>
            <div className="cardHeader">
                { props.title }
            </div>
            <div className="subhead">What I’ll do</div>
            <div className="body">
                { props.todoStart }
                <div className="signoff">- Allison</div>
            </div>
            <form id="modalForm">
                <input type="hidden" id="request" name="request" value={ props.title } />
                <div className="bodyIntake">
                    { props.todoIntake.map((it:any, i:number) => (
                        <Intake it={ it } key={ i } />
                    ))}
                </div>
                <div className="subhead">Anything else we should know?</div>
                <div className="textarea">
                    <textarea id="anything_else" name="anything_else" rows={4}  placeholder="The more we know, the faster we can give you support!"></textarea>
                </div>
                <div className="assignment"><span className="avatar allison"></span><span className="name">Allison</span> is ready to help with this.</div>
                <div className="buttonWrapper">
                    <input type="submit" className="button primary" value="Do this for me" />
                </div>
            </form>
        </div>
    )
}


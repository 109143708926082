import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import TodoBrowser from './root';
import TodoBrowserReg from "./todo";
import ErrorPage from "./error-page";
import CardSetUnregistered from "./discover";
import CardSetRegistered from "./home";
import Nav from "./nav";
import Admin, { CardsEditor } from "./admin";
import Ideas from "./ideas";

const router = createBrowserRouter([
  {
    path: "/",
    element: <TodoBrowser />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/create",
    element: <Admin />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin",
    element: <CardsEditor />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/discover",
    element: <CardSetUnregistered />,
  },
  {
    path: "/home",
    element: <CardSetRegistered />,
  },
  {
    path: "/ideas",
    element: <Ideas />,
  },
  {
    path: "/browse",
    element: <TodoBrowserReg />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);